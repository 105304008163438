* {
    margin: 0;
    font-family: 'Plus Jakarta Sans', sans-serif;
    /* font-family: 'Source Code Pro', monospace; */
    font-size: 12px;
    font-weight: 400;
    user-select: none;
}

:root {
    --icon-color: black;
    --alt-icon-color: black;
    --text-color: black;
    --alt-text-color: black;
    --border-color: black;
    --alt-border-color: black;
    --dash-background-color: black;
    --alt-dash-background-color: black;
    --user-online-color: black;
}

body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}