.addIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 15px;
    pointer-events: auto;
    cursor: pointer;
}

.addIcon {
    position: relative;
    width: 11px;
    height: 11px;
    /* background-color: red; */
}

.addIcon > div:first-of-type {
    width: 1px;
    height: 10px;
    margin-left: 5px;
    background-color: var(--icon-color);
}

.addIcon > div:last-of-type {
    width: 1px;
    height: 10px;
    margin-top: -10px;
    margin-left: 5px;
    background-color: var(--icon-color);
    transform: rotate(90deg);
}