#login {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    color: var(--text-color);
    font-size: 12px;
    animation: fadeIn 2s ease-in-out;
}

#login > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 400px;
    /* border: 1px solid var(--border-color); */
    border-radius: 20px;
    background-color: var(--alt-dash-background-color);
}

#login > div > img {
    width: 120px;
    margin-top: 60px;
    margin-bottom: 30px;
}

#loginButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#loginButtonContainer > input {
    margin-bottom: 10px;
    color: var(--text-color);
    font-size: 12px;
    outline: none;
    border: 1px solid var(--alt-border-color);
    border-radius: 4px;
    background-color: var(--alt-dash-background-color);

}

.loginButton {
    margin-top: 20px;
    padding: 2px 10px;
    border: 1px solid var(--alt-border-color);
    border-radius: 20px;
    color: var(--alt-text-color);
    pointer-events: auto;
    cursor: pointer;
    background-color: transparent;

}

.loginButton:first-of-type {
    margin-bottom: 10px;
}

#loggingInContainer {
    display: flex;
    margin-left: 20px;
}