@keyframes fadeIn {
    0%   {opacity: 0}
    100% {opacity: 1}
}

#visual {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

#mainCanvas {
    /* position: fixed; */
    z-index: 1;
    animation: fadeIn 3s linear;
    /* background-color: red; */
}

#miniMapBox {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid white;
    opacity: 0.2;
    border-radius: 5px;
}

.fileInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    width: 70%;
    /* animation: fadeIn 0.5s ease-in-out; */
}

.fileInfo > p {
    width: max-content;
    /* color: white; */
    font-size: 10px !important;
    text-align: center;
    word-break: break-all;
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 20px;
    border: 1px solid var(--border-color);
    background-color: rgba(255, 255, 255, 0.8);
    /* transition: transform 0.05s ease-in-out; */
    /* background-color: red; */
}
