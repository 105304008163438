@keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

#dashboard {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    /* background-color: lime; */
}

#availableDataRoomsButton span,
#dataRoomOptionsButton span {
    position: absolute;
    top: 4px;
    right: 10px;
}

#availableDataRoomsButton .up,
#dataRoomOptionsButton .up {
    top: 6px;
}

#availableDataRoomsOptionsContainer {
    max-height: calc(100vh - 80px);
    margin-top: 10px;
    margin-left: 14px;
}

#availableDataRoomsOptions {
    height: 60vh;
    /* border: 1px solid var(--border-color); */
    border-radius: 10px;
    background-color: var(--alt-dash-background-color);
    backdrop-filter: blur(2px);
    overflow: scroll;
    pointer-events: auto;
}

#availableDataRoomsOptions::-webkit-scrollbar {
    display: none;
}

.availableDataRoomsOption {
    width: max-content;
    color: var(--alt-text-color) !important;
    font-size: 12px;
    padding-top: 5px;
    margin-bottom: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    pointer-events: auto;
    cursor: pointer;
}

.availableDataRoomsOption:first-of-type {
    margin-top: 10px;
}


#availableDataRoomsOption:hover {
    background: radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, 0.2), transparent, transparent);
}

.selectedDataRoom {
    margin-left: 14px;
    color: var(--alt-text-color) !important;
    border-radius: 20px;
    /* background-color: var(--dash-background-color); */
    background-color: #ffffff64;
}

#availableDataRoomsContainerInnerLower {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

#availableDataRoomsContainerInnerLower input {
    margin-bottom: 20px;
    color: var(--text-color);
    font-size: 12px;
    border: 1px solid var(--text-color);
    pointer-events: auto;
    outline: none;
}

#dataRoomOptions {
    width: max-content;
    margin-top: 15px;
    pointer-events: none;
}

.dataRoomOption {
    display: flex;
    justify-content: space-between;
}

.dataRoomOption p {
    color: var(--text-color);
    font-size: 12px;
    padding-top: 5px;
    margin-bottom: 15px;
}

.dataRoomOption input {
    height: 16px;
    color: var(--text-color);
    font-size: 12px;
    border-left: 0px solid white;
    border-right: 0px solid rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid var(--text-color);
    border-top: 0px solid rgba(255, 255, 255, 0.4);
    margin-left: 10px;
    pointer-events: auto;
    outline: none;
}

.growButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    color: var(--alt-text-color);
    font-size: 12px;
    border-radius: 20px;
    padding: 2px 10px;
    margin-top: 15px;
    pointer-events: auto;
    cursor: pointer;
    background-color: var(--alt-dash-background-color);

}

#groupDivider {
    margin-left: 30px;
    margin-bottom: 10px;
    color: var(--alt-text-color) !important;
}

#menuContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    z-index: 10;
    width: 100%;
    bottom: 0;
    left: 0;
    opacity: 0;
}

#mobileMenuContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    z-index: 10;
}

#mobileMenuContainer > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 20px;
    margin: 0px 5px;
    pointer-events: auto;
    cursor: pointer;
}

#mobileMenuContainer>div:hover {
    background: radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, 0.2), transparent, transparent);
}

.menu {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 290px;
    height: 200px;
}

.menuOption {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 66px;
    height: 66px;
    border: 1px solid var(--border-color);
    border-radius: 66px;
    color: var(--text-color);
    font-size: 12px;
    font-style: italic;
    pointer-events: auto;
    transform: translateY(20px);
    opacity: 0;
    cursor: pointer;
}

.menuOption:hover {
    background: radial-gradient(ellipse at 20% 20%, rgba(255, 255, 255, 0.3), transparent);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
}

.menuOption:nth-of-type(1) {
    top: calc(50% - 33px);
    left: 0;
}

.menuOption:nth-of-type(2) {
    top: -3%;
    left: 12%;
}

.menuOption:nth-of-type(3) {
    top: -20%;
    left: calc(50% - 33px);
}

.menuOption:nth-of-type(4) {
    top: -3%;
    right: 12%;
    /* pointer-events: none; */
}

.menuOption:nth-of-type(5) {
    top: calc(50% - 33px);
    right: 0;
}

.menuOptionSelected {
    background: radial-gradient(ellipse at 20% 20%, rgba(255, 255, 255, 0.3), transparent);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
}

#statusBar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 10px;
    perspective: 800px;
    /* background-color: lime; */
}

#statusBar p {
    color: var(--text-color);
    font-size: 12px;
    padding: 2px 15px;
}

#statusBarLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--text-color);
    font-size: 12px;
    padding-top: 10px;
    padding-left: 10px;
}

#statusBarLeft > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

#statusBarLeft p {
    margin-bottom: 10px;
}

#statusBarLeft > img {
    width: 150px;
    margin-left: 12px;
    margin-bottom: 20px;
}

.dataRoomPlusButton {
    pointer-events: auto;
    cursor: pointer;
    padding: 1px 5px !important;
    margin-left: 10px;
}

.dataRoomMinusButton {
    align-self: flex-start;
    pointer-events: auto;
    cursor: pointer;
    padding: 1px 0px !important;
}

#statusBarRight {
    display: flex;
    padding-top: 10px;
    padding-right: 10px;
}


#statusBarRight > p {
    margin-left: 10px;
}

#statusBarRight > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 15px;
    margin-left: 10px;
    cursor: pointer;
    pointer-events: auto;
    /* transform: scale(1.3); */
}

#statusBarRight>div:hover {
    background: radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, 0.2), transparent, transparent);
}

#statusBarLower {
    display: flex;
    position: absolute;
    bottom: 10px;
    left: 10px;
}

#statusBarLower>p {
    margin-right: 10px;
}

#cameraPosition {
    display: flex;
    justify-content: flex-start;
    /* width: 138px; */
}

.visibleDataRoom {
    padding: 2px 15px;
    margin-bottom: 10px;
    pointer-events: auto;
    cursor: pointer;
}

.visibleDataRoom > span {
    margin-left: 10px;
}

.userInDataRoomListItem {
    margin: 10px 0px 0px 0px !important;
}

#currentDataRoom {
    margin-left: 14px;
    color: var(--alt-text-color) !important;
    border-radius: 20px;
    background-color: var(--alt-dash-background-color);
}

#inspectorWidthButton {
    pointer-events: auto;
    cursor: pointer;
}