#folderContentsStatusBar {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 20px;
    bottom: 0;
    left: 0;
    border-top: 1px solid var(--alt-border-color);
    /* background-color: rgba(255, 255, 255, 0.2); */
}

.folderContentsItemContainer {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 12px;
    border-right: 1px solid var(--alt-border-color);
}

.folderContentsItemContainer>p {
    padding: 0px 15px;
}

.folderContentsItemContainer>.label {
    top: 120%;
}



#folderContentsInner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 20px);
    height: calc(100% - 60px);
    margin: 0px 10px;
    padding-top: 30px;
}

.pathElement {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 2px;
}

.pathElementInner {
    display: flex;
}

.pathElementInner>a {
    color: white;
    font-size: 12px;
    text-decoration: none;
}

.pathElementInner>p {
    margin-left: 10px;
}

#pathInformation {
    height: 100px;
}

#pathIconContainer {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 8px 0px 2px 9px;
    pointer-events: auto;
    cursor: pointer;
}

#pathIcon {
    display: flex;
    width: 12px;
    height: 10px;
    /* background-color: red; */
}

/* #pathIcon>div:nth-child(odd) {
    width: 2px;
    height: 20px;
    margin-top: 3px;
    border: 1px solid var(--alt-border-color);
} */

/* #pathIcon>div:nth-child(even) {
    width: 2px;
    height: 1px;
    margin-top: 4px;
    background-color: rgba(255, 255, 255, 0.4);
} */

#files {
    display: grid;
    overflow: scroll;
}

#files::-webkit-scrollbar {
    display: none;
}

#path {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid var(--alt-border-color);
}

.file {
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    border: 1px solid var(--alt-border-color);
    border-radius: 10px;
    margin: 0px 5px 20px 5px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.2);
}

.fileSearchResult {
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    border: 1px solid rgba(255, 0, 0, 0.4);
    border-radius: 10px;
    margin: 0px 5px 20px 5px;
    overflow: hidden;
    background-color: rgba(255, 0, 0, 0.2);
}

.file>div,
.fileSearchResult>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.fileIcon {
    width: 5px;
    height: 5px;
    border: 1px solid white;
    border-radius: 5px;
}

.fileType {
    margin-top: 4px;
    font-size: 7px;
}

.file>a,
.fileSearchResult>a {
    display: flex;
    align-items: center;
    width: calc(100% - 40px);
    height: 100%;
    color: white;
    font-size: 12px;
    text-decoration: none;
    word-break: break-all;
    margin-left: 10px;
    /* background-color: red; */
}

#columnsContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 40px;
    height: 80%;
    /* padding: 0px 15px; */
    border-right: 1px solid var(--alt-border-color);
    cursor: pointer;
}

.column {
    width: 1px;
    height: 55%;
    background-color: white;
}

#numberOfFiles {
    margin-bottom: 10px;
}