#search {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-top-left-radius: 10px 10px;
    border-top-right-radius: 10px 10px;
    border-bottom-right-radius: 10px 10px;
    border-bottom-left-radius: 10px 10px;
    pointer-events: none;
    /* transform: translateY(10px); */
    transition: height 0.3s ease-in-out;
    /* opacity: 0; */
    overflow: hidden;
}

#searchStatusBar {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 20px;
    bottom: 0;
    left: 0;
    border-top: 1px solid var(--alt-border-color);
    /* background-color: rgba(255, 255, 255, 0.2); */
}

#searchStatusBar > p {
    color: white;
    font-size: 12px;
    padding: 0px 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
}

#searchContainerInner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* position: absolute;
    bottom: 25px; */
    height: calc(100% - 50px);
    width: 100%;
    margin-top: 30px;
    /* background-color: blue; */
}

#searchTypeButton {
    height: 17px;
    margin-right: 10px;
    padding: 0px 20px 0px 10px;
    color: white;
    font-size: 12px;
    font-style: italic;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    pointer-events: auto;
    backdrop-filter: blur(1px);
    cursor: pointer;
}

#searchTypeButton span, .maxMinOptionsButton span, .panelOptionHeader span {
    position: absolute;
    width: 15px;
}

.up {
    top: 2px;
    right: 1px;
    transform: rotate(0deg);
}

.down {
    right: 8px;
    transform: rotate(180deg);
}

#searchCategoryOptions {
    height: 100%;
    width: calc(100% - 20px);
    margin-left: 10px;
    color: var(--alt-text-color);
    font-size: 12px;
}

.searchCategory {
    display: inline-block;
    width: max-content;
    padding: 1px 5px;
    margin: 2px;
    border: 1px solid var(--alt-border-color);
    border-radius: 20px;
    pointer-events: auto;
    cursor: pointer;
}

.searchCategorySelected {
    opacity: 1;
}

.searchCategoryUnselected {
    opacity: 0.5;
}

#searchText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 30px);
    margin-left: 15px;
    border-top: 1px solid var(--alt-border-color);
}

#searchText input {
    width: calc(100% - 6px);
    height: 15px;
    margin-bottom: 6px;
    border: 1px solid var(--alt-border-color);
    border-radius: 20px;
    background-color: transparent;
    color: white;
    font-size: 12px;
    font-style: italic;
    pointer-events: auto;
    outline: none;
}

#searchTextDropDownButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    margin-top: 1px;
    margin-left: -20px;
    color: white;
    font-size: 12px;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    pointer-events: auto;
    cursor: pointer;
}

#searchTextDropDownButton p {
    height: 75%;
}

#textDropDown {
    align-self: flex-start;
    width: max-content;
    margin-bottom: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    pointer-events: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* background-color: lime; */
}

#textDropDown::-webkit-scrollbar {
    display: none;
}

.textDropDownOptions {
    display: table;
    padding: 5px 0px;
    color: white;
    font-size: 12px;
    word-break: break-word;
    /* border-top: 1px solid rgba(255, 255, 255, 0.2); */
    pointer-events: auto;
    cursor: pointer;
}

.textDropDownOptions:hover {
    /* background: radial-gradient(ellipse at 200% -300%, rgba(255, 255, 255, 0.2), transparent); */
    /* background: radial-gradient(ellipse at 10% 10%, rgba(255, 255, 255, 0.2), transparent, transparent); */
}

#searchInfo {
    display: flex;
    align-items: center;
    width: calc(100% - 30px);
    height: 20px;
    margin-bottom: 8px;
    font-size: 12px;
    color: white;
    white-space: nowrap;
    /* background-color: lime; */
}

#searchClear {
    margin-right: 10px;
    pointer-events: auto;
    cursor: pointer;
    color: var(--text-color);
}

#searchBarContainerInspector > #searchInfo {
    margin-left: 15px;
}

#searchBarContainerInspector > #searchInfo > #searchClear {
    color: var(--alt-text-color);
}

#searchRange {
    display: flex;
    align-items: center;
    height: 19px;
}

.fromToLabel {
    margin-right: 10px;
    color: white;
    font-size: 12px;
}

.maxMinOptionsButton {
    position: relative;
    width: max-content;
    height: 17px;
    margin-right: 20px;
    color: white;
    font-size: 12px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    padding: 0px 15px 0px 5px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.2);
    pointer-events: auto;
}

.maxMinOptions {
    position: absolute;
    top: 27px;
    left: -6px;
}

.maxMinOptions p {
    width: max-content;
    text-align: left;
    margin: 0px 0px 5px 6px;
}

.maxMinOptions p:hover {
    /* background: radial-gradient(ellipse at 200% -300%, rgba(255, 255, 255, 0.2), transparent); */
    background: radial-gradient(ellipse at 10% 10%, rgba(255, 255, 255, 0.2), transparent, transparent);
}

.available {
    color: white;
    border-top: 1px solid white;
    pointer-events: auto;
}

.notAvailable {
    color: rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    pointer-events: none;
}

#rangeSearchButton {
    height: 17px;
    /* margin-left: 10px; */
    padding: 0px 10px 0px 10px;
    color: white;
    font-size: 12px;
    font-style: italic;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    pointer-events: auto;
}

.showMore {
    margin-top: 15px;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
}

#searchBarContainerMenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* background-color: lime; */
}

#searchBarContainerMenu > #searchText {
    width: 25%;
    margin-left: 0px;
    border-top: none;
    /* background-color: lime; */
}

#searchBarContainerMenu > #searchText > #textDropDown {
    align-self: center;
    margin-bottom: 0px;
    border-left: 1px solid white;
    border-top: 1px solid white;
    border-right: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
}

#searchBarContainerMenu > #searchText > input {
    width: 100%;
    margin-bottom: 30px;
    padding: 5px 10px;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    background-color: transparent;
    pointer-events: auto;
    color: var(--text-color);
    outline: none;
    font-size: 12px;
    backdrop-filter: blur(1px);
}

#searchBarContainerMenu > #searchInfo {
    position: absolute;
    left: calc(65% + 20px);
    bottom: 25px;
}