.folderContentsIconContainerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 15px;
    /* border-left: 1px solid rgba(255, 255, 255, 0.4); */
}

.folderContentsIconContainerStatusBar {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 0px 2px 9px;
}

.folderContentsIcon {
    width: 11px;
    height: 11px;
}

.folderContentsIcon > div {
    width: 2px;
    height: 2px;
    border-radius: 2px;
    border: 1px solid var(--icon-color);
}

.folderContentsIconAlt > div {
    border: 1px solid var(--alt-icon-color);
}

.folderContentsIcon > div:nth-of-type(2) {
    margin-top: -2px;
    margin-left: 6px;
}

.folderContentsIcon > div:last-of-type {
    margin-left: 4px;
}